'use client';

import { cn } from '@nextui-org/theme';
import * as React from 'react';
import { Skeleton } from '@nextui-org/skeleton';

import { BmwThreeDotsSvg, BmwThreeStrikesSvg } from '@/assets/svgs';

interface SectionTitleLoadingProps {
  notation?: 'strike' | 'dot';
  className?: string;
  classNames?: {
    title: string;
    notation?: string;
  };
}

const SectionTitleLoading: React.FC<SectionTitleLoadingProps> = ({
  notation = 'strike',
  className,
  classNames,
}) => {
  return (
    <div className={cn('flex flex-row items-center gap-4', className)}>
      {notation === 'strike' && <BmwThreeStrikesSvg className={cn('w-9', classNames?.notation)} />}
      {notation === 'dot' && <BmwThreeDotsSvg className={cn('w-9', classNames?.notation)} />}

      <Skeleton className={cn('h-12 w-56 max-w-md', classNames?.title)} />
    </div>
  );
};

export default SectionTitleLoading;
